import AnimatedNumber from "animated-number-react";
import { useRef } from "react";
import useIsVisible from "./useIsVisible.jsx";

export const Untitled = (props) => {
  let { viewData } = props;
  const ref = useRef();
  let isVisible = useIsVisible(ref);
  let length = viewData?.items?.length;

  return (
    <div
      id="numberGrid"
      className={`grid grid-cols-1 sm:grid-cols-${length} items-center justify-center gap-2`}
      ref={ref}
      style={{
        background: viewData?.config?.bgColor,
        color: viewData?.config?.textColor,
      }}
    >
      {isVisible &&
        viewData?.items?.map((item) => {
          let formatValue = (value) =>
            `${Number(value.toFixed(0)).toLocaleString()}`;
          return (
            <div
              key={item.title}
              className="col-span-auto p-4 flex flex-col items-center justify-center lg:justify-between h-36"
            >
              <h1 className="mb-2 lg:text-lg font-semibold text-center">
                {item?.title}
              </h1>
              <div className="text-2xl lg:text-4xl font-bold flex justify-center items-center">
                <div className="bg-blue-300 w-32 lg:w-48 bg-opacity-10 inline-block p-2 rounded-lg text-center">
                  <AnimatedNumber
                    className=""
                    value={Number(item?.number)}
                    formatValue={formatValue}
                    duration={2500}
                    delay={300}
                    key={isVisible}
                  />
                </div>
                <span className="p-1">{item?.text}</span>
              </div>
            </div>
          );
        })}
    </div>
  );
};
