import "./Marquee.css";

export default function Label(props) {
  const { fontSize, paddingTop, paddingBottom, colorScheme, labelText } =
    props.labelConfig;
  return (
    <div className="overflow-hidden w-screen h-auto">
      <div
        id={props.anchorID}
        style={{
          fontSize: fontSize + "px",
          paddingTop: paddingTop + "px",
          paddingBottom: paddingBottom + "px",
          backgroundRepeat: "repeat-x",
          backgroundImage: colorScheme
            ? colorScheme
            : "linear-gradient(to right, #0063a8, #0081bc, #249ecc, #4ebbda, #78d8e7)",
          color: "white",
          backgroundColor: "#e7c447",
        }}
        className={`sliding-background overflow-hidden`}
      >
        <p
          className="sliding-text sm:px-16 h-full text-center"
          dangerouslySetInnerHTML={{
            __html: labelText,
          }}
        ></p>
      </div>
    </div>
  );
}
