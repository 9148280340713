import React, { useState } from "react";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import { Row, Col } from "reactstrap";
import { Link, NavLink } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { IconName } from "@fortawesome/fontawesome-svg-core";
import IconButton from "@material-ui/core/IconButton/IconButton";
import ReactModal from "react-modal";
import OCVVideoPlayer from "../../OCVFeatures/OCVVideoPlayer/OCVVideoPlayer";
import FacebookWidget from "../FacebookWidget";
import TwitterWidget from "../TwitterWidget";
import OCVBlogSlider from "../../OCVFeatures/OCVBlog/OCVBlogSlider/OCVBlogSlider";
import HorizontalSublayout from "../HorizontalSublayout/HorizontalSublayout";
import "./TopBar.css";

interface Props {
  // How to add to functional equivalent?
  classes: any;
  topbarConfig: any;
  manifestData: any;
  anchorID?: any;
}

ReactModal.setAppElement("#root");

export default function TopBar(props: Props) {
  const [showModal, setModal] = useState(false);
  const [modalView, setModalView] = useState(
    Object.keys(props.manifestData["views"])[0]
  );
  const [modalTitle, setModalTitle] = useState("");

  const handleOpenModal = (view: string, title: string) => {
    setModal(true);
    setModalView(view);
    setModalTitle(title);
  };
  const handleCloseModal = () => {
    setModal(false);
  };

  const { classes, topbarConfig, manifestData, anchorID } = props;
  return (
    <Row
      id={anchorID}
      className={classes.topBar + " " + topbarConfig?.additionalTailwindStyles}
    >
      {topbarConfig["alignment"] === "right" ? (
        <Col className={classes.topSocial} xs={10}>
          <List
            className={
              classes.topSocialList +
              " flex lg:flex-row-reverse justify-center items-center space-x-3 "
            }
          >
            {topbarConfig["links"]
              ? topbarConfig["links"].map((item: any) =>
                  item.link ? (
                    <ListItem key={item.title} className={classes.topLinksItem}>
                      <a
                        rel="noopener noreferrer"
                        target={"_blank"}
                        className={
                          item.isButton
                            ? classes.smallButton + "   break-words"
                            : classes.topLink + "  break-words "
                        }
                        href={item.link}
                      >
                        {item.title}
                      </a>
                    </ListItem>
                  ) : (
                    <ListItem
                      key={item.title}
                      className={classes.topLinksItem + "   break-words"}
                    >
                      {manifestData["features"][item.feature]["type"] ===
                      "modal" ? (
                        <span
                          className={
                            item.isButton
                              ? classes.smallButton
                              : classes.topLink
                          }
                          onClick={() =>
                            handleOpenModal(
                              manifestData["features"][item.feature]["view"],
                              manifestData["features"][item.feature]["title"]
                            )
                          }
                        >
                          {item.title}
                        </span>
                      ) : (
                        <NavLink
                          to={"/" + item.feature}
                          className={
                            item.isButton
                              ? classes.smallButton + "   break-words"
                              : classes.topLink + "  break-words "
                          }
                        >
                          {item.title}
                        </NavLink>
                      )}
                    </ListItem>
                  )
                )
              : ""}
            {topbarConfig["socialMedia"].map(
              (item: { type: IconName; link: string | undefined }) => (
                <ListItem
                  key={item.type}
                  className={
                    classes.topSocialListItem +
                    " p-0 h-10 w-12 items-center block"
                  }
                >
                  <a
                    rel="noopener noreferrer"
                    target={"_blank"}
                    aria-label={item.type}
                    className={
                      classes.topSocialLink +
                      " w-full h-full flex items-center justify-center text-center "
                    }
                    href={item.link}
                  >
                    <FontAwesomeIcon size={"lg"} icon={["fab", item.type]} />
                  </a>
                </ListItem>
              )
            )}
          </List>
        </Col>
      ) : (
        ""
      )}
      {topbarConfig["alignment"] === "left" ? (
        <Col className={classes.topSocial} xs={10}>
          <List className={classes.topSocialList}>
            <ListItem className={classes.topLinksButton + "   "}>
              <NavLink className={classes.smallButton} to={"/viewOurApp"}>
                VIEW OUR APP
              </NavLink>
            </ListItem>
            {topbarConfig["links"]
              ? topbarConfig["links"].map((item: any) =>
                  item.link ? (
                    <ListItem
                      key={item.title}
                      className={classes.topLinksItem + "   "}
                    >
                      <a
                        rel="noopener noreferrer"
                        target={"_blank"}
                        className={classes.topLink}
                        href={item.link}
                      >
                        {item.title}
                      </a>
                    </ListItem>
                  ) : (
                    <ListItem
                      key={item.title}
                      className={classes.topLinksItem + "   "}
                    >
                      <NavLink
                        to={"/" + item.feature}
                        className={classes.topLink}
                      >
                        {item.title}
                      </NavLink>
                    </ListItem>
                  )
                )
              : ""}
            {topbarConfig["socialMedia"].map(
              (item: { type: IconName; link: string | undefined }) => (
                <ListItem
                  key={item.type}
                  className={classes.topSocialListItem + "   "}
                >
                  <a
                    rel="noopener noreferrer"
                    target={"_blank"}
                    aria-label={item.type}
                    className={classes.topSocialLink}
                    href={item.link}
                  >
                    <FontAwesomeIcon size={"lg"} icon={["fab", item.type]} />
                  </a>
                </ListItem>
              )
            )}
          </List>
        </Col>
      ) : (
        ""
      )}
      {topbarConfig["alignment"] === "both" ? (
        <>
          <Col className={classes.topLinks} xs={10} lg={5}>
            <List className={classes.topLinksList}>
              <ListItem className={classes.topLinksButton + "   "}>
                <NavLink className={classes.smallButton} to={"/viewOurApp"}>
                  VIEW OUR APP
                </NavLink>
              </ListItem>
              {topbarConfig["links"]
                ? topbarConfig["links"].map((item: any) =>
                    item.link ? (
                      <ListItem
                        key={item.title}
                        className={classes.topLinksItem + "   "}
                      >
                        <a
                          rel="noopener noreferrer"
                          target={"_blank"}
                          className={classes.topLink}
                          href={item.link}
                        >
                          {item.title}
                        </a>
                      </ListItem>
                    ) : (
                      <ListItem
                        key={item.title}
                        className={classes.topLinksItem + "   "}
                      >
                        <NavLink
                          to={"/" + item.feature}
                          className={classes.topLink}
                        >
                          {item.title}
                        </NavLink>
                      </ListItem>
                    )
                  )
                : ""}
              <ListItem className={classes.topLinksItem + "   "}>
                <NavLink className={classes.topLink} to={"/search"}>
                  Search
                </NavLink>
              </ListItem>
            </List>
          </Col>
          <Col className={classes.topSocial} xs={10} lg={5}>
            <List className={classes.topSocialList}>
              {topbarConfig["socialMedia"].map(
                (item: { type: IconName; link: string | undefined }) => (
                  <ListItem
                    key={item.type}
                    className={classes.topSocialListItem + "   "}
                  >
                    <a
                      rel="noreferrer noopener"
                      target={"_blank"}
                      aria-label={item.type}
                      className={classes.topSocialLink}
                      href={item.link}
                    >
                      <FontAwesomeIcon size={"lg"} icon={["fab", item.type]} />
                    </a>
                  </ListItem>
                )
              )}
            </List>
          </Col>
        </>
      ) : (
        ""
      )}
      <ReactModal
        className="TopBarModalContent"
        overlayClassName="TopBarModal"
        contentLabel="Minimal Modal Example"
        onRequestClose={() => handleCloseModal()}
        isOpen={showModal}
      >
        <div className="TopBarDiv">
          <h4 className="TopBarModalTitle">{modalTitle}</h4>
          <IconButton
            aria-label="Close"
            className="TopBarCloseButton"
            onClick={() => handleCloseModal()}
          >
            <FontAwesomeIcon className="TopBarIcon" icon={["fas", "times"]} />
          </IconButton>
        </div>
      </ReactModal>
    </Row>
  );
}
