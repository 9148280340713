import React from "react";
import { Link } from "react-router-dom";

export default function OCVBlogGridWidget(props: any) {
  const { viewData } = props;

  const [blogData, setBlogData] = React.useState<any>([]);

  React.useEffect(() => {
    fetch(viewData.url, {
      method: "GET",
      mode: "cors",
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((response) => response.json())
      .then((data) => setBlogData(data));
  }, [viewData]);

  const config = viewData?.config;

  return (
    <>
      <div
        className={`pb-4 bg-cover py-12 ${config?.additionalTailwindStyles}`}
      >
        <h2
          className="text-2xl sm:text-3xl md:text-4xl tracking-tight font-extrabold uppercase text-center"
          style={{
            color: viewData.config.titleColor
              ? viewData.config.titleColor
              : "black",
          }}
          id={props.anchorID}
        >
          {viewData.title}
        </h2>
        <hr
          className="mx-auto w-32 mt-4 py-0.5"
          style={{
            background: viewData.config.hrColor
              ? viewData.config.hrColor
              : "black",
          }}
        />

        <div
          className={`grid grid-cols-3 md:flex pt-4 justify-center items-center`}
        >
          {blogData.map((blog: any) => (
            <div className=" mx-2 my-3 md:flex-1" key={blog?.title}>
              <div className="pb-4">
                <a
                  target={viewData?.config?.external ? "_blank" : "_self"}
                  rel={"noopener noreferrer"}
                  href={
                    !blog?.title.includes("http")
                      ? "https://" + blog?.title
                      : blog?.title
                  }
                >
                  <img
                    alt={blog?.title}
                    src={blog?.images[0]?.large}
                    className="mx-auto"
                  />
                </a>
              </div>
            </div>
          ))}
        </div>
      </div>
    </>
  );
}
