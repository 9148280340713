import { Col, Row } from "reactstrap";
import "./TwoColumnView.css";

export default function TwoColumnView(props: any) {
  const { manifestData, layoutConfig } = props;
  return (
    <div
      className="w-full text-center space-y-4 pb-4"
      style={{ backgroundColor: layoutConfig?.bgColor }}
    >
      {layoutConfig?.showTitle && (
        <>
          <h1
            className="text-black font-semibold text-xl sm:text-4xl pt-4 mb-2"
            dangerouslySetInnerHTML={{ __html: layoutConfig.header }}
          />
          <div
            style={{ borderColor: layoutConfig?.divideColor }}
            className="mt-3 mb-2 border-b-4 w-1/6"
          />
        </>
      )}

      <Row
        className={
          layoutConfig?.additionalTailwindStyles + "  w-11/12 mx-auto space-y-3"
        }
      >
        <Col className="" xs={10} sm={6}>
          <p
            className="md:my-8 text-left text-sm sm:text-base lg:text-lg xl:text-2xl text-black font-semibold"
            dangerouslySetInnerHTML={{ __html: layoutConfig.text }}
          />
        </Col>
        <Col
          className="md:my-8 flex justify-center items-center"
          style={{ paddingLeft: 0, paddingRight: 0 }}
          xs={10}
          sm={6}
        >
          <img
            className="max-h-96"
            src={
              manifestData["stylesheet"].images
                ? manifestData["stylesheet"].images[layoutConfig.img]["url"]
                : ""
            }
            alt={
              manifestData["stylesheet"].images
                ? manifestData["stylesheet"].images[layoutConfig.img]["altText"]
                : ""
            }
          />
        </Col>
      </Row>
    </div>
  );
}
