import React from "react";
import default_image from "../../../assets/default_image.jpeg";

export default function OCVBlogGridPage(props: any) {
  const { viewData } = props;

  const [blogData, setBlogData] = React.useState<any>([]);

  React.useEffect(() => {
    fetch(viewData.url)
      .then((response) => response.json())
      .then((data) => setBlogData(data));
  }, [viewData.url]);

  let cols = viewData.config.numCols;

  const config = viewData?.config;
  return (
    <div
      id={props.anchorID}
      className={"pb-4 bg-cover " + config?.additionalTailwindStyles}
      style={{
        backgroundColor: viewData?.config?.bgColor
          ? viewData.config.bgColor
          : "#eee",
      }}
    >
      <div
        className={`w-10/12 mx-auto grid auto-rows-fr grid-cols-1 sm:grid-cols-2 lg:grid-cols-${
          cols * 2
        } pt-4`}
      >
        {blogData.map((item: any, index: number) => (
          <div
            key={item?.title + index}
            className="col-span-1 lg:col-span-2 mx-2 my-3"
          >
            <div className="flex flex-col justify-between items-center h-full">
              <img
                alt=""
                src={
                  item?.images[0]?.large
                    ? item?.images[0]?.large
                    : default_image
                }
                className={`w-auto max-h-96 object-cover mx-auto ${
                  viewData?.config?.rounded ? "rounded-full" : null
                }`}
              />
              <div className="space-y-2 p-2">
                {!viewData?.config?.hideTitle && (
                  <h2
                    dangerouslySetInnerHTML={{ __html: item?.content }}
                    className={`text-center my-2 break-all h-12`}
                  />
                )}
                <a
                  className="flex items-center justify-center"
                  target={viewData?.config?.external ? "_blank" : "_self"}
                  rel={"noopener noreferrer"}
                  href={
                    !item?.title.includes("http")
                      ? "https://" + item?.title
                      : item?.title
                  }
                >
                  <button
                    className={`mt-2 mx-auto rounded-full border-2 font-medium border-${viewData.config.buttonBorderColor} text-${viewData.config.buttonTextColor} p-2`}
                  >
                    {viewData.config.buttonText}
                  </button>
                </a>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}
